import React, { FC } from 'react';

import NextErrorComponent, { ErrorProps } from 'next/error';

const NotFound404Page: FC<ErrorProps> = () => {
  // Do not record an exception in Sentry for 404
  return <NextErrorComponent statusCode={404} title="Ops! Essa página não existe" />;
};

export default NotFound404Page;
